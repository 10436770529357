import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
// import { StaticImage } from 'gatsby-plugin-image'
import Logo from '../svg/mario_kruzic_logo.svg'

library.add(fab, fas)

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <>
      <nav className='relative flex flex-wrap items-center justify-between py-1 bg-brand'>
        <div className='container flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <a
              className='text-sm font-bold leading-relaxed inline-block mr-4 py-0 whitespace-nowrap uppercase text-white'
              href='/'>
              <Logo className='h-16' />
              {/* <StaticImage
                src='../images/mariokruzic-logo-trans.png'
                alt='Mario Kružić logo'
                quality='100'
                imgClassName='p-0'
                height='60'
              /> */}
            </a>

            <button
              className='text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <FontAwesomeIcon icon='bars' />
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center' +
              (navbarOpen ? ' flex' : ' hidden')
            }
            id='example-navbar-danger'>
            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center text-sm uppercase font-bold leading-snug text-white'>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2 '>
                <a className='hover:opacity-75' href='/'>
                  <FontAwesomeIcon icon={['fas', 'home']} />
                  <span className='ml-2'>Naslovna</span>
                </a>
              </li>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2'>
                <a className=' hover:opacity-75' href='/biografija'>
                  <span className='ml-2'>Gradonačelnik</span>
                </a>
              </li>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2'>
                <a className=' hover:opacity-75' href='/zamjenik'>
                  <span className='ml-2'>Zamjenik</span>
                </a>
              </li>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2'>
                <a className=' hover:opacity-75' href='/lista'>
                  <span className='ml-2'>Lista</span>
                </a>
              </li>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2'>
                <a className=' hover:opacity-75' href='/program'>
                  <span className='ml-2'>Program</span>
                </a>
              </li>
              <li className='nav-item px-2 my-1 lg:m-0 lg:py-2'>
                <a className='hover:opacity-75' href='/dokumenti'>
                  <span className='ml-2'>Dokumenti</span>
                </a>
              </li>

              <li className='nav-item px-2 my-0.5 ml-2 lg:m-0 lg:py-2'>
                <a
                  className='hover:opacity-75 text-xl'
                  href='https://www.facebook.com/kruzicmario/'>
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </a>
              </li>
              <li className='nav-item px-2 my-0.5 ml-2 lg:m-0 lg:py-2'>
                <a
                  className='hover:opacity-75 text-xl'
                  href='https://www.youtube.com/channel/UCm29s_iWlsJGKYvINy65irg'>
                  <FontAwesomeIcon icon={['fab', 'youtube']} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
