import React from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Logo from '../svg/mario_kruzic_logo.svg'
import Koalicija from '../svg/mario_kruzic_koalicija.svg'

library.add(fab)

export default function Footer({ children }) {
  return (
    <>
      <section className='footer-wrapper bg-brand text-white py-3'>
        <footer className='container text-sm flex flex-row justify-center items-center'>
          {/* <StaticImage
            src='../images/mariokruzic-logo-trans.png'
            alt='Marinko Žic logo'
            placeholder='blurred'
            quality='100'
            imgClassName='p-2'
            height='60'
          /> */}
          <Logo className='flex-shrink max-h-20' />

          <a
            className='p-2 hover:opacity-75 text-2xl'
            href='https://www.facebook.com/kruzicmario/'>
            <FontAwesomeIcon icon={['fab', 'facebook']} />
          </a>
          <a
            className='p-2 hover:opacity-75 text-2xl mr-10'
            href='https://www.youtube.com/channel/UCm29s_iWlsJGKYvINy65irg'>
            <FontAwesomeIcon icon={['fab', 'youtube']} />
          </a>
          <Koalicija className='flex-shrink max-h-20' />
          {/* <StaticImage
            src='../images/mariokruzic-koalicija.png'
            alt='SDP PGS'
            placeholder='blurred'
            quality='100'
            imgClassName='p-2'
            height='70'
          /> */}
        </footer>
      </section>
    </>
  )
}
